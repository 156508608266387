h1 {
  display: grid;
  position: relative;
  place-items: center;
}

.aboutme {
  display: grid;
  place-items: center;
  color: #ccc;
  padding: 20px;
}

.aboutme img {
  max-width: 50%; 
  height: auto; 
  border-radius: 8px;
  margin-bottom: 10px;
}

.text-subcontainer img {
  float: right;
  margin-right: 5px;
  margin-left: 20px;
  margin-bottom: 10px; 
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.aboutme .text-container {
  text-align: right;
  max-width: 600px;
  margin-left: auto; 
  margin-right: auto; 
}

.aboutme h1 {
  margin-top: 0%;
}

.aboutme p {
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: start;
}

