.project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
  }
  
  .project-card {
    max-width: 550px;
    margin: 10px;
    padding: 15px;
    border: 1px solid #CCC;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  }
  
  .project-card h2 {
    margin-top: 10px;
    color:  #9f8170; 
  
  }
  
  a{
    color: #9f8170;  
  }

  li{
    color: #CCC;
  }
  h3{
    color: #9f8170;  
  }
  
  .project-card p {
    margin-top: 5px;
    margin-bottom: 10;
    color: #CCC;
    line-height: 2;
  }